
@media (max-width:769px) {

}
@media (min-width:769px) {
    
}


.wallets-usdt{
    b{
        font-size: 22px;
      
    }
    .usdt-pl60{
        padding: 30px 0 6px;
    }
    .usdt-p30{
        padding: 30px;
    }
    .fz18{
        font-size: 16px;
    }
}