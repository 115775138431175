@media (max-width:769px) {
    .faq-page{
        .faq-query{
            text-align: center;
            width: 100%;
        }
        .faq-yiwen{
            display: none;
        }
    }
}
@media (min-width:769px) {
    .faq-page{
        .faq-query{
            text-align: left;
            width: 100%;
        }
        .faq-yiwen{
            display: block;
        }
    }
}
.lih-50{
    line-height: 50px;
    font-size: 14px;
    text-align: left;
}
.ant-collapse-borderless > .ant-collapse-item{
    border-bottom: 1px dashed #d9d9d9;
}

.faq-page{

}