
@media (max-width:769px) {
    .kycCertification{
        .avatar-uploader > .ant-upload {
            width: 200px;
            height: 128px;
            img{
                object-fit: contain;
            }
        }
    }
    .avatar-uploader > .ant-upload {
        img{
            object-fit: contain;
        }
    }
}

@media (min-width:769px) {
    .avatar-uploader > .ant-upload {
        width: 100%;
        height: 128px;
        img{
            object-fit: contain;
        }
      }
}
.kycCertification{
    .kyc-usdt-p{
        border-bottom: 1px solid #f0f0f0;
        padding: 10px;
        display: flex;
        align-items: center;
        .kyc-dis-title{
            width: 80px;;
        }
        input{
            width: 300px;;
        }
    }
    
}