
@media (max-width:769px) {
    .Collectionlist-usdt{
        .coll-list-button{
            .btn{
                width: 100px;
                margin-bottom: 10px;
                margin-right: 20px;
            }
        }
    }
}

@media (min-width:769px) {
    .Collectionlist-usdt{
        .btn{
            width: 100px;
            margin-right: 20px;
        }
    }
}

.Collectionlist-usdt{
    .coll-list-button{

    }
}