

.Deposit-usdt{
    b{
        font-size: 22px;
      
    }
    .usdt-pl60{
        padding: 30px 0 6px;
    }
    .fz16{
        font-size: 16px;
        padding: 30px 0 6px;
    }
    .fz-16{
        font-size: 16px;
    }
    .cr06b{
        color:#06b572;
    }
}