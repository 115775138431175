



.Ordhory-Detail{
    b{
        font-size: 22px;
      
    }
    .usdt-pl60{
        padding: 30px 0 6px;
    }
    .gr{
        .ant-list-item-meta-description{
            color:green;
        }
        
    }
    .red{
        .ant-list-item-meta-description{
            color: red;
        }
       
    }
}