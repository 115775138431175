.agreement-compon{
    
    h3{
        height: 30px;
        margin-top: 20px;
    }
    p{
        line-height: 50px;
        height: auto !important;
        margin-bottom: 0;
        font-size: 14px;
    }
}