

@media (max-width:769px) {
    .Trader-page{
        .trader-bannerimg{
            display: none;
        }
        .ant-carousel .slick-slide {
            text-align: center;
            line-height: 20px;
            // background: #364d79;
            overflow: hidden;
          }
        .Trader-rise{
            img{
                width:100%;
                height: 300px;
                // object-fit: contain;
            }
        }
        .Trader-free{
            display: flex; 
            justify-content: center;
        }
    }
}
@media (min-width:769px) {
    .Trader-page{
        .trader-bannerimg{
            display: block;
        }
        .ant-carousel .slick-slide {
            text-align: center;
            line-height: 30px;
            // background: #364d79;
            overflow: hidden;
          }
        .Trader-rise{
            img{
                width:500px;
                height: 250px;
                object-fit: contain;
            }
        }
        .Trader-free{

        }
    }
}


.Trader-page{
    .trader-rs{
        img{
            width: 90px;
            height: 90px;
            object-fit: contain;
        }
    }
    .fz22{
        font-size: 22px;
        line-height:40px;
    }
    .fz32{
        font-size: 32px;
        font-weight: 500;
    }
    .Trader-rise{
        div:nth-of-type(1){
           
        }
        div{
            font-size: 16px;
            line-height: 40px;
            // padding: 10px;
        }
       
        img{
          
        }
    }
    .Trader-banner{
        img{
            width: 483px;
        }
    }
}
