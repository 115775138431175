@media (max-width:769px) {
    .otc-footer{
        .ant-col{
            width: 100%;
        }
    }
}
@media (min-width:769px) {
    
}

.otc-footer{
    p{
        font-size: 14px;;
    }
}