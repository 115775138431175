@media (max-width:769px) {
    .about-connect{
        img{
             height: 350px;
             width: 100%;
             object-fit: cover;
             display: none;
        }
    }
}
@media (min-width:769px) {
    .about-connect{
        img{
             height: 350px;
             width: 483px;
             object-fit: cover;
             display: block;
        }
    } 
}
.about-page{
    .about-one{
        text-align: left;
        padding: 9%;
    }
    .ant-carousel .slick-slide {
        // background: #001529;
        background: url('../static/xingkong.jpg');
        background-position: 50%;
        background-size: cover;
        
        h3,h4{
            // color: black;
            font-weight: 400;
        }
       
   }
   .about-connect{
    padding-bottom: 80px;
       h4{
           text-align: center;
       }
       div{
        font-size: 14px;
       }
      
       line-height: 36px;
       text-align: left;
      
   }
}